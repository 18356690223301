import React from 'react';
import Layout from 'components/Layout';
import ErrorFallback from 'components/ErrorFallback';

const ErrorPage = () => (
  <Layout noPadding>
    <ErrorFallback resetError={() => {}} />
  </Layout>
);

export default ErrorPage;
